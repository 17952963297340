import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "welcome-to-redliner"
    }}>{`Welcome to Redliner`}</h1>
    <p>{`RedLiner is a React component designed to easily visualize CSS/style properties on JSX/HTML elements.`}</p>
    <p>{`When RedLiner is wrapped around display components like buttons and inputs, RedLiner will visually show the properties in an easy to understand way.`}</p>
    <div>
To get started, view the <Link to="/redliner/" mdxType="Link">RedLiner examples</Link>.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      